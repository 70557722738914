import * as Factory from "factory.ts";

import {
  AnonQuoteInput,
  CreateAnonCashOutQuoteInput,
  CreateCashOutQuoteInput,
  QuoteInput,
  QuoteQuery,
} from "@src/generated/sdk";
import { walletInstrumentFactory } from "./walletInstrument";
import { fiatInstrumentFactory } from "./fiatInstrument";
import {
  AnonCashInQuote,
  AnonCashOutQuote,
  Api,
  Asset,
  AuthorizedCashInQuote,
  AuthorizedCashOutQuote,
  CAIPAsset,
} from "@src/types";
import { assetFromCAIPAsset } from "@tigris/common";
import { ok } from "neverthrow";
import { nanoid } from "nanoid";
import { MESO_MAX_AMOUNT } from "@src/utils/constants";

export const generateQuoteExpiration = (delayInSeconds = 30) => {
  const expirationDate = new Date();
  // add 30 seconds to the current time
  expirationDate.setTime(new Date().getTime() + delayInSeconds * 1000);

  return expirationDate.toISOString();
};

const mapQuoteDecimals = (
  destinationAsset:
    | AnonQuoteInput["destAsset"]
    | QuoteInput["destAsset"]
    | CreateCashOutQuoteInput["destinationAsset"]
    | CreateAnonCashOutQuoteInput["destinationAsset"],
): number => {
  if (
    destinationAsset.startsWith("eip155:1/erc20") ||
    destinationAsset === CAIPAsset.SOLANA_MAINNET_USDC ||
    destinationAsset === CAIPAsset.SOLANA_DEVNET_USDC
  ) {
    return 6;
  } else if (destinationAsset.startsWith("eip155")) {
    return 18;
  } else {
    return 9;
  }
};

const mapExchangeRateBase = (currency: Asset): CAIPAsset => {
  switch (currency) {
    case Asset.USDC:
      return CAIPAsset.SOLANA_MAINNET_USDC;
    case Asset.ETH:
      return CAIPAsset.ETHEREUM_MAINNET_ETH;
    case Asset.SOL:
      return CAIPAsset.SOLANA_MAINNET_SOL;
    case Asset.MATIC:
    case Asset.POL:
      return CAIPAsset.POLYGON_MAINNET_MATIC;
    case Asset.BTC:
      return CAIPAsset.BITCOIN_MAINNET_BTC;
    case Asset.USD:
      return CAIPAsset.FIAT_USD;
    case Asset.EUR:
      return CAIPAsset.FIAT_EUR;
  }
};

export function calculateExchangeRate(
  sourceAmount: string | undefined,
  destinationAmount: string | undefined,
): string {
  const subtotal = Number(sourceAmount) || 0;
  const destination = Number(destinationAmount) || 1;
  return (subtotal / destination).toFixed(2);
}

function getRandomFloat(min: number, max: number, decimals: number) {
  const str = (Math.random() * (max - min) + min).toFixed(decimals);

  return parseFloat(str);
}

const generateQuoteJwt = (
  quote: Omit<QuoteQuery["quote"], "jwt">,
  expiration: string,
) =>
  `ignore.${btoa(
    JSON.stringify({ ...quote, exp: new Date(expiration).getTime() }),
  )}.ignore`;

export const generateAuthenticatedQuote = (
  input: QuoteInput,
): AuthorizedCashInQuote => {
  const quoteCurrency = assetFromCAIPAsset(input.destAsset);
  const exchangeRateBase = mapExchangeRateBase(quoteCurrency);

  let sourceAmount;
  let destinationAmount;

  if (input.sourceAmount) {
    sourceAmount = input.sourceAmount!;
    destinationAmount = String(getRandomFloat(0.0000002, 0.49999999, 4));
  }

  if (input.destAmount) {
    sourceAmount = String(getRandomFloat(25.0, 1000.0, 2));
    destinationAmount = input.destAmount!;
  }

  const quote = {
    __typename: "Quote" as const,
    expiration: generateQuoteExpiration(),
    sourceAsset: {
      network: "fiat:x",
      address: "USD",
      symbol: "USD",
      decimals: 2,
    },
    destAsset: {
      network: input.destAsset.split("/")[0],
      address: "501",
      symbol: quoteCurrency,
      decimals: mapQuoteDecimals(quoteCurrency),
    },
    sourceInstrument: fiatInstrumentFactory.build(),
    destInstrument: walletInstrumentFactory.build(),
    sourceTotal: {
      amount: String(getRandomFloat(0.005, 0.1, 2)),
      currency: "USD",
    },
    sourceTotalPresented: {
      amount: String(getRandomFloat(0.005, 0.1, 2)),
      currency: "USD",
    },
    mesoFeeWaived: false,
    mesoFee: { amount: "1.85", currency: "USD" },
    mesoFeePresented: { amount: "1.85", currency: "USD" },
    networkFee: {
      amount: String(getRandomFloat(0.004, 1.45, 2)),
      currency: "USD",
    },
    networkFeePresented: {
      amount: String(getRandomFloat(0.004, 1.45, 2)),
      currency: "USD",
    },
    networkFeeEstimate: {
      amount: String(getRandomFloat(0.004, 1.45, 2)),
      currency: "USD",
    },
    networkFeeWaived: false,
    sourceSubtotal: { amount: sourceAmount!, currency: "USD" },
    sourceSubtotalPresented: { amount: sourceAmount!, currency: "USD" },
    destination: {
      amount: destinationAmount!,
      currency: quoteCurrency,
    },
    monthlyLimit: { amount: String(MESO_MAX_AMOUNT), currency: "USD" },
    monthlyUsage: { amount: "1234", currency: "USD" },
    exchangeRate: {
      rate: calculateExchangeRate(sourceAmount, destinationAmount),
      quote: CAIPAsset.FIAT_USD,
      base: exchangeRateBase,
    },
  };

  return {
    ...quote,
    jwt: generateQuoteJwt(quote, quote.expiration),
  };
};

export const generateAnonQuote = (input: AnonQuoteInput): AnonCashInQuote => {
  const quoteCurrency = assetFromCAIPAsset(input.destAsset);
  const exchangeRateBase = mapExchangeRateBase(quoteCurrency);

  let sourceAmount;
  let destinationAmount;

  if (input.sourceAmount) {
    sourceAmount = input.sourceAmount!;
    destinationAmount = String(getRandomFloat(0.0000002, 0.49999999, 4));
  }

  if (input.destAmount) {
    sourceAmount = String(getRandomFloat(25.0, 1000.0, 2));
    destinationAmount = input.destAmount!;
  }

  return {
    __typename: "AnonQuote",
    expiration: generateQuoteExpiration(),
    sourceAsset: {
      network: "fiat:x",
      address: "USD",
      symbol: "USD",
      decimals: 2,
    },
    destAsset: {
      network: input.destAsset.split("/")[0],
      address: "501",
      symbol: assetFromCAIPAsset(input.destAsset),
      decimals: mapQuoteDecimals(input.destAsset),
    },
    sourceTotal: {
      amount: String(getRandomFloat(0.005, 0.1, 2)),
      currency: "USD",
    },
    sourceTotalPresented: {
      amount: String(getRandomFloat(0.005, 0.1, 2)),
      currency: "USD",
    },
    mesoFeeWaived: false,
    mesoFee: { amount: "1.85", currency: "USD" },
    mesoFeePresented: { amount: "1.85", currency: "USD" },
    networkFee: {
      amount: String(getRandomFloat(0.004, 1.45, 2)),
      currency: "USD",
    },
    networkFeePresented: {
      amount: String(getRandomFloat(0.004, 1.45, 2)),
      currency: "USD",
    },
    networkFeeEstimate: {
      amount: String(getRandomFloat(0.004, 1.45, 2)),
      currency: "USD",
    },
    networkFeeWaived: false,
    sourceSubtotal: { amount: sourceAmount!, currency: "USD" },
    sourceSubtotalPresented: { amount: sourceAmount!, currency: "USD" },
    destination: {
      amount: destinationAmount!,
      currency: assetFromCAIPAsset(input.destAsset),
    },
    exchangeRate: {
      rate: calculateExchangeRate(sourceAmount, destinationAmount),
      quote: CAIPAsset.FIAT_USD,
      base: exchangeRateBase,
    },
  };
};

export const generateCreateAnonCashOutQuote = (
  input: CreateAnonCashOutQuoteInput,
): AnonCashOutQuote => {
  const quoteCurrency = assetFromCAIPAsset(input.sourceAsset);
  const mesoFee = String(getRandomFloat(0.004, 1.45, 2));

  return {
    __typename: "AnonCashOutQuote",
    sourceAsset: {
      network: input.sourceAsset,
      address: "501",
      symbol: quoteCurrency,
      decimals: 6,
    },
    destAsset: {
      network: CAIPAsset.FIAT_USD,
      address: "USD",
      symbol: "USD",
      decimals: 2,
    },
    exchangeRate: {
      rate: "1",
      quote: CAIPAsset.FIAT_USD,
      base: input.sourceAsset,
    },
    sourceTotal: {
      amount: input.sourceAmount,
      currency: quoteCurrency,
    },
    mesoFee: {
      amount: mesoFee,
      currency: "USD",
    },
    sourceSubtotal: {
      amount: input.sourceAmount,
      currency: "USD",
    },
    destination: {
      amount: String(Number(input.sourceAmount) - Number(mesoFee)),
      currency: "USD",
    },
    expiration: generateQuoteExpiration(),
  };
};

export const generateAuthenticatedCashOutQuote = (
  input: CreateCashOutQuoteInput,
): AuthorizedCashOutQuote => {
  const quoteCurrency = assetFromCAIPAsset(input.sourceAsset);
  const mesoFee = String(getRandomFloat(0.004, 1.45, 2));

  return {
    __typename: "CashOutQuote",
    id: `qout_${nanoid(8)}`,
    senderInstrument: walletInstrumentFactory.build(),
    destInstrument: fiatInstrumentFactory.build(),
    depositAddress: "502",
    sourceAsset: {
      network: input.sourceAsset,
      address: "501",
      symbol: quoteCurrency,
      decimals: 6,
    },
    destAsset: {
      network: CAIPAsset.FIAT_USD,
      address: "USD",
      symbol: "USD",
      decimals: 2,
    },
    exchangeRate: {
      rate: "1",
      quote: CAIPAsset.FIAT_USD,
      base: input.sourceAsset,
    },
    sourceTotal: {
      amount: input.sourceAmount,
      currency: quoteCurrency,
    },
    mesoFee: {
      amount: mesoFee,
      currency: "USD",
    },
    sourceSubtotal: {
      amount: input.sourceAmount,
      currency: "USD",
    },
    destination: {
      amount: String(Number(input.sourceAmount) - Number(mesoFee)),
      currency: "USD",
    },
    monthlyLimit: { amount: String(MESO_MAX_AMOUNT), currency: "USD" },
    monthlyUsage: { amount: "1234.00", currency: "USD" },
    expiration: generateQuoteExpiration(),
  };
};

export const authenticatedQuoteInputFactory =
  Factory.Sync.makeFactory<QuoteInput>({
    destAsset: "eip155:1/slip44:60",
    destInstrumentId: "some_id",
    sourceAmount: "100",
    sourceAsset: "fiat:x/iso4217:USD",
    sourceInstrumentId: "some_id",
  });

export const authenticatedDestinationAmountQuoteInputFactory =
  Factory.Sync.makeFactory<QuoteInput>({
    destAsset: "eip155:1/slip44:60",
    destInstrumentId: "some_id",
    destAmount: "1",
    sourceAsset: "fiat:x/iso4217:USD",
    sourceInstrumentId: "some_id",
  });

export const authenticatedQuoteSuccessResponse = async (
  input: QuoteInput,
): ReturnType<Api["resolveQuote"]> => ok(generateAuthenticatedQuote(input));

export const anonQuoteInputFactory = Factory.Sync.makeFactory<AnonQuoteInput>({
  destAsset: "eip155:1/slip44:60",
  sourceAmount: "100",
  sourceAsset: "fiat:x/iso4217:USD",
});

export const anonQuoteDestinationAmountInputFactory =
  Factory.Sync.makeFactory<AnonQuoteInput>({
    destAsset: "eip155:1/slip44:60",
    destAmount: "1",
    sourceAsset: "fiat:x/iso4217:USD",
  });

export const anonQuoteSuccessResponse = async (
  input: AnonQuoteInput,
): ReturnType<Api["resolveAnonQuote"]> => ok(generateAnonQuote(input));

export const authenticatedCashOutQuoteInputFactory =
  Factory.Sync.makeFactory<CreateCashOutQuoteInput>({
    destinationAsset: "fiat:x/iso4217:USD",
    destinationInstrumentId: "some_id",
    sourceAmount: "100",
    sourceAsset: "eip155:1/erc20:0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
    senderInstrumentId: "some_id",
    riskSessionKey: "some_key",
  });

export const authenticatedCashOutQuoteSuccessResponse = async (
  input: CreateCashOutQuoteInput,
): ReturnType<Api["resolveCreateCashOutQuote"]> =>
  ok(generateAuthenticatedCashOutQuote(input));

export const anonCashOutQuoteInputFactory =
  Factory.Sync.makeFactory<CreateAnonCashOutQuoteInput>({
    destinationAsset: "fiat:x/iso4217:USD",
    sourceAmount: "100",
    sourceAsset: "eip155:1/erc20:0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
  });

export const anonCashOutQuoteSuccessResponse = async (
  input: CreateAnonCashOutQuoteInput,
): ReturnType<Api["resolveCreateAnonCashOutQuote"]> =>
  ok(generateCreateAnonCashOutQuote(input));
