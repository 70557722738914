import {
  Button,
  getUS,
  Input,
  nonUSCountries,
  PhoneCountrySelect,
  sortedCountries,
} from "@tigris/mesokit";
import {
  FormEventHandler,
  ForwardedRef,
  forwardRef,
  useCallback,
  useRef,
  useState,
} from "react";
import {
  Country,
  Value,
  formatPhoneNumber,
  isValidPhoneNumber,
} from "react-phone-number-input";
// eslint-disable-next-line no-duplicate-imports
import PhoneInput from "react-phone-number-input";
import { toast } from "sonner";
import AnimationContainer from "./AnimationContainer";
import Heading from "./Heading";
import { useRouter } from "../hooks/useRouter";
import { CountryCodeAlpha2, Routes } from "../types";
import { useApi } from "../hooks/useApi";
import { useOnboarding } from "../hooks/useOnboarding";
import { AutoFocusRef } from "../utils/autoFocusRef";

const TOAST_ID = "PhoneEntry";
const FORM_ID = TOAST_ID;
const INPUT_ID = "phoneInput";

// We need to do this so we can wrap our `Input` with `grow` class since we cannot control the DOM hierarchy of react-phone-number-input
const PositionallyWrappedInput = forwardRef(
  (props, ref: ForwardedRef<HTMLInputElement>) => (
    <div className="grow">
      <Input ref={ref} {...props} />
    </div>
  ),
);

export const PhoneEntry = () => {
  const { navigate } = useRouter();
  const {
    api: { resolveAddPhoneNumber },
    session,
  } = useApi();
  const { updateUser, user } = useOnboarding();
  const [isLoading, setIsLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState<{
    value?: Value;
    isValid: boolean;
    isDirty: boolean;
  }>({
    value: "",
    isValid: false,
    isDirty: false,
  });
  const phoneInputRef = useRef<HTMLInputElement>(null);

  const handleAddPhoneNumber = useCallback<FormEventHandler>(
    async (event) => {
      event.preventDefault();

      setIsLoading(true);

      const addPhoneNumberResult = await resolveAddPhoneNumber({
        input: { phoneNumber: phoneNumber.value! },
      });

      setIsLoading(false);

      if (addPhoneNumberResult.isErr()) {
        toast.error(addPhoneNumberResult.error, { id: TOAST_ID });
      } else {
        updateUser({ phoneNumber: formatPhoneNumber(phoneNumber.value!) });
        navigate(Routes.Phone2Fa);
      }
    },
    [navigate, phoneNumber.value, resolveAddPhoneNumber, updateUser],
  );

  return (
    <AnimationContainer onAnimationComplete={AutoFocusRef(phoneInputRef)}>
      <form
        id={FORM_ID}
        name={FORM_ID}
        onSubmit={handleAddPhoneNumber}
        className="onboarding-inner-content"
      >
        <Heading
          title="Two-Factor Authentication"
          subtitle="Two-factor authentication adds an extra layer of security to your
        account."
        />
        <section className="flex flex-col gap-1">
          <div className="flex items-center rounded-xl bg-neutral-100 dark:bg-neutral-700">
            <PhoneInput
              name={INPUT_ID}
              id={INPUT_ID}
              className="flex w-full"
              placeholder="Your Phone Number"
              defaultCountry={
                (user.residenceCountry as Country) ?? CountryCodeAlpha2.US
              }
              smartCaret={true}
              value={phoneNumber.value}
              inputComponent={PositionallyWrappedInput}
              countryCallingCodeEditable={false}
              countrySelectComponent={PhoneCountrySelect}
              countrySelectProps={{
                static: !session!.euEnabled,
                countries:
                  user.residenceCountry === CountryCodeAlpha2.US
                    ? [getUS()]
                    : sortedCountries(nonUSCountries),
              }}
              international={false}
              limitMaxLength={true}
              data-testid="phone-input"
              isValid={
                phoneNumber.isValid ||
                !(phoneNumber.isDirty && !phoneNumber.isValid)
              }
              // @ts-expect-error: `react-phone-number-input` does not provide a type declaration for this, but it resolves to an `HTMLInputElement`.
              ref={phoneInputRef}
              onChange={(value) => {
                setPhoneNumber((state) => {
                  const isValid =
                    value === "" || value === undefined
                      ? false
                      : isValidPhoneNumber(value as string);
                  const isDirty =
                    state.isValid === false && state.isDirty === true
                      ? false
                      : (value && value.length === 12) ||
                        (!state.isDirty && isValid);

                  return {
                    value,
                    isValid,
                    isDirty,
                  };
                });
              }}
            />
          </div>
        </section>
        <div className="onboarding-footer">
          <Button
            key="PhoneEntry:button"
            disabled={isLoading || !phoneNumber.isValid}
            type="submit"
            isLoading={isLoading}
          >
            Send Code
          </Button>
        </div>
      </form>
    </AnimationContainer>
  );
};
