import {
  ChangeEventHandler,
  FormEventHandler,
  useCallback,
  useState,
} from "react";
import AnimationContainer from "./AnimationContainer";
import Heading from "./Heading";
import { Button, LabelledInput } from "@tigris/mesokit";
import { Routes } from "../types";
import { useRouter } from "../hooks/useRouter";
import { useApi } from "../hooks/useApi";
import { toast } from "sonner";
import { validatePesel } from "../utils/validation";
import { Posthog, TelemetryEvents } from "@tigris/common";

const FORM_ID = "PeselEntry";

export const PeselEntry = () => {
  const { navigate } = useRouter();
  const { api } = useApi();
  const [peselNumber, setPeselNumber] = useState("");
  const [isTouched, setIsTouched] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const handlePeselNumberChange = useCallback<
    ChangeEventHandler<HTMLInputElement>
  >(
    (event) => {
      const peselNumber = event.target.value;

      const valueIsValid = validatePesel(peselNumber);

      if (isTouched && !valueIsValid) {
        setIsValid(false);
      } else if (valueIsValid && !isTouched) {
        setIsValid(true);
        setIsTouched(true);
      } else {
        setIsValid(valueIsValid);
      }

      setPeselNumber(event.target.value);
    },
    [isTouched],
  );

  const handleSubmit = useCallback<FormEventHandler<HTMLFormElement>>(
    async (event) => {
      event.preventDefault();

      toast.dismiss();

      setIsLoading(true);

      const addPeselResult = await api.resolveAddPesel({
        input: { pesel: peselNumber },
      });

      if (addPeselResult.isErr()) {
        toast.error(addPeselResult.error);
        setIsLoading(false);
        Posthog.capture(TelemetryEvents.onboardingPeselEntryFailure);
        return;
      }

      Posthog.capture(TelemetryEvents.onboardingPeselEntrySuccess);

      navigate(Routes.ResidentialAddress);
    },
    [api, navigate, peselNumber],
  );

  return (
    <AnimationContainer>
      <form
        id={FORM_ID}
        name={FORM_ID}
        data-testid={FORM_ID}
        onSubmit={handleSubmit}
        className="onboarding-inner-content"
      >
        <Heading
          title="PESEL Number"
          subtitle="This is an 11-digit identification number for Polish citizens and residents."
        />

        <LabelledInput
          labelProps={{ text: "ID Number" }}
          type="number"
          inputMode="numeric"
          pattern="[0-9]*"
          name="peselNumber"
          value={peselNumber}
          onChange={handlePeselNumberChange}
          onBlur={() => setIsTouched(true)}
          placeholder="Number"
          data-testid="PeselEntry:number"
          isValid={isValid}
        />

        <div className="onboarding-footer">
          <Button
            key="PeselEntry:button"
            disabled={!isValid || isLoading || !isTouched}
            type="submit"
            isLoading={isLoading}
          >
            Continue
          </Button>
        </div>
      </form>
    </AnimationContainer>
  );
};
