import {
  Asset,
  Position,
  PostMessageBus,
  TransferIframeParams,
  AssetAmount,
  Network,
  CAIPAsset,
  CountryCodeAlpha2,
} from "@tigris/common/src/types";
import {
  AnonQuoteQuery,
  CheckTransferQuery,
  CreateAnonCashOutQuoteMutation,
  CreateCashOutQuoteMutation,
  ExecuteTransferMutation,
  FiatInstrument,
  FullProfileStatusFragment,
  InstrumentKind,
  PartnerQuery,
  QuoteQuery,
  SingleUseInstrument,
  TransferKind,
  UserActivation,
  UserFragment,
} from "./generated/sdk";
import { SardineEnvironment } from "@sardine-ai/react-js-wrapper";
import { PublicClient } from "viem";
import { Path } from "react-router-dom";
import { ThemeName, UserLimits } from "@tigris/mesokit";
import { GraphQLError } from "graphql";
import { api } from "@src/api";
import { Result } from "neverthrow";

// Export all _types_ from `common` into this types module
export * from "@tigris/common/src/types";

type SOLWalletAddress = {
  kind: Asset.SOL;
  /**
   * The raw wallet address.
   */
  address: string;
  /**
   * The shortened address for display.
   */
  truncatedAddress: string;
};

type ETHWalletAddress = {
  kind: Asset.ETH;
  /**
   * The raw wallet address.
   */
  address: string;
  /**
   * The shortened address for display.
   */
  truncatedAddress: string;
  /**
   * If available, the [ENS](https://ens.domains/) name associated with the address.
   */
  ens: Awaited<ReturnType<PublicClient["getEnsName"]>>;
};

/**
 * Placeholder values for wallet addresses to be used as fallbacks.
 */
type FormattedWalletAddress = {
  kind: "unknown";
  /**
   * The raw wallet address. If unreadable, this value will be `"..."`.
   */
  address: string;
  /**
   * The shortened address (first 10 chars) for display. If unreadable, this value will be `"..."`.
   */
  truncatedAddress: string;
};

/**
 * Presentation details for the user's wallet address for the current session.
 */
export type WalletAddress =
  | SOLWalletAddress
  | ETHWalletAddress
  | FormattedWalletAddress;

/**
 * User specific attributes.
 *
 */
export type User = Partial<
  Pick<
    UserFragment,
    "id" | "status" | "email" | "theme" | "limits" | "firstName" | "lastName"
  >
> &
  Partial<{
    fiatInstruments: Extract<
      UserFragment["fiatInstruments"],
      { __typename: "FiatInstruments" }
    >;

    walletInstruments: Extract<
      UserFragment["walletInstruments"],
      { __typename: "WalletInstruments" }
    >;

    depositAddressInstruments: Extract<
      UserFragment["depositAddressInstruments"],
      { __typename: "DepositAddressInstruments" }
    >;
  }> & {
    /**
     * A string representing the user's phone.
     */
    phone: string;
    /**
     * The user's theme selection, will return `"default"` if they have not selected a theme.
     */
    theme: ThemeName;

    /**
     * The status of the user's onboarding activation. This is only at the end of onboarding and is used to determine the terminal UI state for the user.
     */
    activation?: UserActivation;

    /** The user's residential address. */
    residentialAddress?: Omit<
      Extract<UserFragment["residentialAddress"], { __typename: "Address" }>,
      "__typename"
    >;

    /** When set, the declared country of residence for this user. */
    residenceCountry?: CountryCodeAlpha2;
  };

export interface RiskSession {
  userId: string;
  sessionKey: string;
  clientId: string;
  environment: SardineEnvironment;
}

/**
 * Representation of authenticated Meso session
 */
export interface Session {
  id: string;
  token: string;
  riskSession: RiskSession;
  mesoLimits: { min: AssetAmount; max: AssetAmount };
  isReturningUser: boolean;
  passkeysEnabled: boolean;
  applepayEnabled: boolean;
  euEnabled: boolean;
  deviceCountry?: string;
}

/** The application-level model for padding/offset of the Landing/Transfer sheet. */
export type Offset = Record<"horizontal" | "vertical", number>;

/**
 * The application-level configuration model stored after parsing configuration.
 */
export type Configuration = Omit<
  TransferIframeParams,
  | "sourceAsset"
  | "destinationAsset"
  | "layoutOffset"
  | "layoutPosition"
  | "mode"
  | "network"
  | "sourceAmount"
> & {
  layout?: {
    offset: Offset;
    position: Position;
  };
  sourceAmount?: AssetAmount;
  sourceAsset?: Asset;
  sourceCAIPAsset: CAIPAsset;
  destinationAmount?: AssetAmount;
  network: Network;
  destinationAsset: Asset;
  transferKind: TransferKind;
  destinationCAIPAsset: CAIPAsset;
  /**
   * An optional correlation ID that can be provided in the standalone integration and associated to quotes/transfers.
   */
  externalId?: string;
  /**
   * An optional url that can be provided in the standalone integration and used to redirect the window after a transfer is approved.
   */
  redirectUrl?: string;
  /**
   * Optional content for the button presented to users at the end of the [standalone integration](https://developers.meso.network/guides/standalone-window) flow.
   *
   * This value will only be used if `redirectUrl` is also provided.
   *
   * If omitted, and `redirectUrl` is provided, the default copy will be rendered (`"Back to <partner.displayName>"`)
   */
  returnButtonContent?: string;
};

export type Api = ReturnType<typeof api>;

/**
 * A constrained React Router `Path` that requires `pathname` and `search`
 */
export type InternalLinkPath = Required<Pick<Path, "pathname" | "search">>;

/** An object representing the content to populate a persistent announcement banner. */
export type AnnouncementBanner = Record<"title" | "body", string>;

/**
 * The root context for the MesoJS flow.
 */
export type AppContextValue = {
  configuration: Readonly<Configuration>;
  bus?: Readonly<PostMessageBus>;
  user: User;
  /** If the user is logged in and we have received an authenticated quote, we populate this domain model for handling limits. */
  userLimits?: UserLimits;
  session?: Session;
  updateSession: (session: Partial<Session>) => void;
  updateUser: (
    userProfile: Partial<User>,
    supportedPaymentMethods?: InstrumentKind[],
  ) => void;
  resetUser: () => void;
  transfer?:
    | Extract<
        ExecuteTransferMutation["executeTransfer"],
        { __typename: "Transfer" }
      >
    | Extract<CheckTransferQuery["transfer"], { __typename: "Transfer" }>;

  api: Api;

  /**
   * Set the active `Transfer` on context to poll for updates
   */
  setTransfer: (
    transfer:
      | Extract<
          ExecuteTransferMutation["executeTransfer"],
          { __typename: "Transfer" }
        >
      | Extract<CheckTransferQuery["transfer"], { __typename: "Transfer" }>
      | undefined,
  ) => void;
  /**
   * The key used when creating Sonner's Toaster component
   */
  toasterId: number;
  /**
   * Function to clear existing Sonner toasts
   */
  clearToasts: () => void;

  partner?: PartnerQuery["partner"];

  /** Allows passing partial updates to the configuration model. Currently, only `sourceAmount` is allowed. This is useful for cases such as amount editing. */
  updateConfiguration: (
    configuration: Pick<Configuration, "sourceAmount">,
  ) => void;

  /**
   * An app-level flag to signal when the user has entered the Amount Editor. Components elsewhere in the application should watch this value and lock navigation, submission, secondary actions while it is active.
   */
  isEditingAmount: boolean;

  /** Sets the value of `isEditingAmount` to `true`. */
  engageAmountEditor: (
    sourceAction: "amount_click" | "header_button_click" | "auto",
  ) => void;

  /** Sets the value of `isEditingAmount` to `false`. */
  closeAmountEditor: () => void;

  /** An app-level flag tp signal when the user is adding a card. */
  isAddingCard: boolean;

  /** Sets the value of `isAddingCard` to the provided boolean value. */
  setIsAddingCard: (connecting: boolean) => void;

  /** Indicates whether a transfer is currently executing */
  executeTransferRequestIsInFlight: boolean;

  /** Sets whether a transfer is currently executing */
  setExecuteTransferRequestIsInFlight: (inFlight: boolean) => void;

  /** Whether the user has a passkey as determined by the presence of the `meso:hasPasskey` field in local storage. */
  hasPasskey: boolean;

  /** Sets the meso:hasPasskey in local storage. */
  setHasPasskey: (hasPasskey: boolean) => void;

  /** Whether the browser supports WebAuthn */
  browserSupportsWebAuthn: boolean;

  /**
   * The qualified [origin](https://developer.mozilla.org/en-US/docs/Web/API/Location/origin) for the API.
   */
  apiOrigin: string;

  /**
   * Indicates whether the initiation sequence for the application is complete. This can be used to determine when to render the first screen to users once data is resolved.
   */
  appReady: boolean;

  /**
   * The modality of the current integration. This is driven by the routing constructs of the application and can be stored on context to avoid passing as props everywhere.
   *
   * This is no longer driven from the query params.
   */
  mode: IntegrationMode;

  /**
   * Whether the user has met or exceeded their transfer limit.
   */
  quoteLimitReached: boolean;

  setQuoteLimitReached: () => void;

  /**
   * Whether an error has been detected in the context or in another component. This will allow components without immediate relationships to react to certain global-level errors.
   */
  hasContextError: boolean;

  /** If present, contains the content for a persistent announcement banner. */
  announcementBanner?: AnnouncementBanner;

  /**
   * The fiat instrument to use for transfer, available when user is authenticated.
   * Can either be a FinancialInstrument or SingleUseInstrument.
   */
  fiatInstrument?: FiatInstrument | SingleUseInstrument;

  /**
   * Sets the fiatInstrument to use for transfer
   */
  setFiatInstrument: (instrument: FiatInstrument | SingleUseInstrument) => void;
};

export type InitializeOnboardingOptions = {
  /** A callback for when the user returns to the transfer window and wishes to login. If omitted, the Onboarding Context provider will attempt to navigate to login. */
  onReturnToTransferLogin?: () => void;
  /** A callback for when onboarding fails (usually due to user activation). If omitted, the Onboarding Context provider will attempt to route to `TransferUnavailable`. */
  onReturnToTransferOnboardingTerminated?: () => void;
  /** A callback for when a user successfully onboards. If omitted, the Onboarding Context provider will attempt to route to the transfer sheet. */
  onReturnToTransferOnboardingComplete?: () => void;
  /** A flag for when the initialize onboarding flow is started asynchronously after previous call has been validated. If omitted, will default to false */
  isAsynchronous?: boolean;
};

export type OnboardingContextValue = {
  initializeOnboarding: (
    profileStatus?: FullProfileStatusFragment,
    user?: Partial<User>,
    options?: InitializeOnboardingOptions,
  ) => void;

  /**
   * Whether an onboarding window/session is in progress.
   */
  onboardingInProgress: boolean;

  /**
   * Forcibly cancel onboarding regardless of the user's progress. Use with caution.
   *
   * This will noop if onboarding is not in progress.
   */
  cancelOnboarding: () => void;

  /**
   * List of InstrumentKind that the integration method supports.
   */
  supportedPaymentMethods: InstrumentKind[];
};

export type ModalOnboardingContextValue = Pick<
  AppContextValue,
  | "api"
  | "appReady"
  | "bus"
  | "configuration"
  | "partner"
  | "session"
  | "updateSession"
  | "updateUser"
  | "user"
  | "mode"
>;
export type CheckoutContextValue = {
  /** Publishes risk data and returns deviceSessionId or error */
  retrieveDeviceSessionId: () => Promise<Result<string, string>>;
};

export type ApplePayContextValue = {
  applePayCanceled: boolean;
  setApplePayCanceled: () => void;
  applePayEnabled: boolean;
  applePayInitiativeContext?: string;
};

export type LoginLandingView = "email" | "password" | "passkey";

/**
 * A generalized struct to allow sending error messages without throw-ing.
 */
export type GenericError = { message: string };

/**
 * Manually extracted types from the MSW library. We can remove these once
 * we upgrade to msw@v2.
 */

// We cannot access this export from MSW directly until we upgrade to v2.
export type GraphQLClientResponse<Data> = {
  status: number;
  headers: Headers;
  data: Data;
  extensions?: unknown;
  errors?: GraphQLError[];
};

export type GraphQLResponse<T = unknown> = {
  data?: T;
  errors?: GraphQLError[];
  extensions?: unknown;
  status: number;
  [key: string]: unknown;
};

/**
 * The modality of the current integration.
 */
export enum IntegrationMode {
  /** The integration is running in a first-party Meso window. */
  STANDALONE = "standalone",
  /** The integration is running in multiple iframes (main and onboarding) in a partner window. */
  INLINE = "inline",
  /** The integration is running within an iframe in a partner window. */
  EMBEDDED = "embedded",
  /** The integration is running in a native iOS or Android web view. */
  WEBVIEW = "webview",
}

export type AnonCashInQuote = Extract<
  AnonQuoteQuery["anonQuote"],
  { __typename: "AnonQuote" }
>;
export type AuthorizedCashInQuote = Extract<
  QuoteQuery["quote"],
  { __typename: "Quote" }
>;
export type CashInQuote = AnonCashInQuote | AuthorizedCashInQuote;
export type AnonCashOutQuote = Extract<
  CreateAnonCashOutQuoteMutation["createAnonCashOutQuote"],
  { __typename: "AnonCashOutQuote" }
>;
export type AuthorizedCashOutQuote = Extract<
  CreateCashOutQuoteMutation["createCashOutQuote"],
  { __typename: "CashOutQuote" }
>;
export type CashOutQuote = AnonCashOutQuote | AuthorizedCashOutQuote;

export type UseQuoteHook = {
  quote?:
    | AnonCashInQuote
    | AuthorizedCashInQuote
    | AnonCashOutQuote
    | AuthorizedCashOutQuote;
  /**
   * Restart polling after dismissing errors.
   */
  restart: () => void;
  /**
   * Stop polling for updates until `restart` is called or the component is unmounted.
   */
  stop: () => void;
};

export type OutletContext = {
  useQuote: UseQuoteHook;
};
