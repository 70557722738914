import {
  Button,
  CountryList,
  CountryListProps,
  CustomToastError,
} from "@tigris/mesokit";
import AnimationContainer from "./AnimationContainer";
import Heading from "./Heading";
import { useApi } from "../hooks/useApi";
import {
  FormEventHandler,
  useCallback,
  useMemo,
  useState,
  useEffect,
} from "react";
import { toast } from "sonner";
import { useRouter } from "../hooks/useRouter";
import { CountryCodeAlpha2, Routes } from "../types";
import { useOnboarding } from "../hooks/useOnboarding";
import { Posthog, TelemetryEvents } from "@tigris/common";

const FORM_ID = "DeclareResidency";
const TOAST_ID = FORM_ID;

export const DeclareResidency = () => {
  const { api, session } = useApi();
  const { updateUser } = useOnboarding();
  const [isLoading, setIsLoading] = useState(false);
  const [countryCode, setCountryCode] = useState<CountryCodeAlpha2 | null>(
    null,
  );
  const { navigate } = useRouter();

  const handleSubmit = useCallback<FormEventHandler<HTMLFormElement>>(
    async (event) => {
      event.preventDefault(); // Prevent form submission

      setIsLoading(true);

      const addResidenceCountryResult = await api.resolveAddResidenceCountry({
        input: { countryCode: countryCode! },
      });

      if (addResidenceCountryResult.isErr()) {
        setIsLoading(false);
        toast.error(addResidenceCountryResult.error, { id: TOAST_ID });
        return;
      }

      updateUser({ residenceCountry: countryCode! });

      Posthog.capture(TelemetryEvents.onboardingDeclareResidency, {
        countryCode: countryCode!,
      });

      navigate(Routes.CreateAccount);
    },
    [api, countryCode, navigate, updateUser],
  );

  const isValid = useMemo(() => countryCode !== null, [countryCode]);

  const handleCountrySelect = useCallback<CountryListProps["onSelectCountry"]>(
    (country) => {
      toast.dismiss(TOAST_ID);
      setCountryCode(country);
    },
    [],
  );

  useEffect(() => {
    if (
      session?.deviceCountry &&
      !(session.deviceCountry in CountryCodeAlpha2)
    ) {
      toast.error(
        <CustomToastError
          title=""
          body={
            <div>
              You are in a country we don&apos;t currently support. Check our{" "}
              <a
                href="https://support.meso.network/hc/en-us/articles/18411224896027-Product-Availability"
                target="_blank"
                rel="noreferrer"
                className="underline opacity-80 transition-opacity hover:opacity-100"
              >
                supported countries
              </a>{" "}
              to continue.
            </div>
          }
        />,
        {
          id: TOAST_ID,
        },
      );
      Posthog.capture(
        TelemetryEvents.onboardingDeclareResidencyUnsupportedCountry,
        {
          countryCode: session.deviceCountry,
        },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AnimationContainer>
      <form
        id={FORM_ID}
        name={FORM_ID}
        data-testid={FORM_ID}
        onSubmit={handleSubmit}
        className="onboarding-inner-content"
      >
        <Heading
          title="Country of Residence"
          subtitle="Where do you currently live?"
        />
        <CountryList
          onSelectCountry={handleCountrySelect}
          disabled={isLoading}
        />

        <div className="onboarding-footer">
          <Button
            disabled={isLoading || !isValid}
            isLoading={isLoading}
            type="submit"
          >
            Continue
          </Button>
        </div>
      </form>
    </AnimationContainer>
  );
};
