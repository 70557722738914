import { AppContext } from "./contexts/AppContext";
import { ErrorMessages } from "@tigris/mesokit";
import { PropsWithChildren, useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { nextAuthStep } from "./utils/authFlow";
import { Routes } from "./utils/constants";
import {
  AuthenticationStrategy,
  UserStatus,
  Message,
  MessageKind,
} from "@src/types";
import { useOnboarding } from "./hooks/useOnboarding";
import { isOnboardingAppRoute } from "./utils/routeHelpers";
import { Sentry } from "@tigris/common";

/**
 *  HeadlessNavigator handles the logic for performing headless wallet
 *  verification. As a functional component in the ReactRouter route hierarchy, we
 *  can pass through and just render children if we are in headless mode.
 *  Otherwise, we can utilize `useEffect` to trigger the business logic for headless
 *  wallet verification and navigate to the onboarding or the transfer sheet
 *  depending on the login response.
 */
export const HeadlessNavigator = ({ children }: PropsWithChildren) => {
  const { pathname, search } = useLocation();
  const {
    configuration: {
      authenticationStrategy,
      walletAddress,
      network,
      transferKind,
    },
    bus,
    api: { resolveLoginMessage, resolveLoginWithWallet },
    session,
    updateUser,
  } = useContext(AppContext);
  const navigate = useNavigate();
  const { initializeOnboarding } = useOnboarding();

  useEffect(() => {
    // use !! operator on session as useEffect dependency and this early return
    // to ensure session exists before initiating headless wallet verification
    if (
      authenticationStrategy !==
        AuthenticationStrategy.HEADLESS_WALLET_VERIFICATION ||
      !session
    ) {
      return;
    }

    // reusable callback to emit error to partner, track error step and duration
    const handleError = (step: string) => {
      bus!.emit({
        kind: MessageKind.ERROR,
        payload: { message: ErrorMessages.headlessNavigator.GENERIC_ERROR },
      });

      const ERROR_MESSAGE = "Headless Wallet Verification Error";

      Sentry.captureMessage(ERROR_MESSAGE, { extra: { step }, level: "error" });
    };

    const headlessWalletVerification = async () => {
      const loginMessageResult = await resolveLoginMessage({
        input: { address: walletAddress, network },
      });

      if (loginMessageResult.isErr()) {
        handleError("loginMessage");
        return;
      }

      bus!.on(
        MessageKind.RETURN_SIGNED_MESSAGE_RESULT,
        async (message: Message) => {
          // only respond to `RETURN_SIGNED_MESSAGE_RESULT`
          if (message.kind !== MessageKind.RETURN_SIGNED_MESSAGE_RESULT) {
            return;
          }

          // partner returned falsey signedMessage (undefined, null, empty string)
          if (!message.payload.signedMessage) {
            handleError("returnSignedMessage");
            return;
          }

          // Login with wallet
          const loginResult = await resolveLoginWithWallet({
            input: {
              address: walletAddress,
              network,
              signature: message.payload.signedMessage,
              riskSessionKey: session ? session.riskSession.sessionKey : "",
            },
          });

          if (loginResult.isErr()) {
            handleError("loginWithWallet");
            return;
          }

          if ("loginWithEmailAndPassword" in loginResult.value) {
            navigate(Routes.LandingSheetLoginLanding);
            return;
          }

          const {
            needsTwoFactor,
            profileStatus,
            userStatus: _userStatus,
          } = loginResult.value;
          const userStatus = _userStatus as UserStatus;

          const updatedUser = { status: userStatus };
          updateUser(updatedUser);

          const nextStep = nextAuthStep({
            profileStatus,
            needsTwoFactor,
            userStatus: userStatus as UserStatus,
            search,
            transferKind,
          });

          if (nextStep.isErr()) {
            handleError("Determining next route");
            return;
          }

          if (isOnboardingAppRoute(nextStep.value.to.pathname)) {
            initializeOnboarding(profileStatus, updatedUser, {
              isAsynchronous: true,
            });
          } else {
            navigate(nextStep.value.to, nextStep.value.options);
          }
        },
      );

      // pass `messageToSign` to partner
      bus!.emit({
        kind: MessageKind.REQUEST_SIGNED_MESSAGE,
        payload: { messageToSign: loginMessageResult.value.messageToSign },
      });
    };

    // trigger async headless wallet verification
    headlessWalletVerification();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!!session]);

  // skip rendering UI on index route when using headless authentication strategy
  return authenticationStrategy ===
    AuthenticationStrategy.HEADLESS_WALLET_VERIFICATION && pathname === "/"
    ? null
    : children;
};
