import { useEffect } from "react";
import { Button } from "@tigris/mesokit";
import { useOnboarding } from "@src/hooks/useOnboarding";
import { toast } from "sonner";
import { useLocation } from "react-router-dom";

const TOAST_ID = "FinishSignup";

export const FinishSignup = () => {
  const { initializeOnboarding } = useOnboarding();
  const { state } = useLocation();

  useEffect(() => {
    toast.info("You'll need to complete signing up before you can continue.", {
      id: TOAST_ID,
    });
  }, []);

  return (
    <Button
      onClick={() => {
        initializeOnboarding(state.profileStatus, state.user);
      }}
      data-testid="FinishSignup"
      className="w-full"
      themeOverride="transfer-button"
    >
      Finish Signup
    </Button>
  );
};
