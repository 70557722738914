import { AppContext } from "@src/contexts/AppContextProvider";
import { Button, ErrorMessages } from "@tigris/mesokit";
import { motion } from "framer-motion";

import { spring } from "@src/utils/animation";
import { toast } from "sonner";
import { useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Routes } from "@src/utils/constants";
import { type Message, MessageKind, UserStatus } from "@src/types";
import { nextAuthStep } from "@src/utils/authFlow";
import { useOnboarding } from "@src/hooks/useOnboarding";
import { isOnboardingAppRoute } from "@src/utils/routeHelpers";
import { Posthog, TelemetryEvents } from "@tigris/common";

type ActiveOperation =
  | "awaiting-signature"
  | "fetching-login-message"
  | "logging-in"
  | null;

const TOAST_ID = "LandingSheet";

export const LandingSheet = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const {
    api: { resolveLoginMessage, resolveLoginWithWallet },
    bus,
    configuration: { network, walletAddress, transferKind },
    session,
    updateUser,
  } = useContext(AppContext);
  const [activeOperation, setActiveOperation] = useState<ActiveOperation>(null);
  const { initializeOnboarding } = useOnboarding();

  const onReturnSignedMessage = async (signedMessage: string) => {
    const loginResult = await resolveLoginWithWallet({
      input: {
        address: walletAddress,
        network,
        signature: signedMessage,
        riskSessionKey: session ? session.riskSession.sessionKey : "",
      },
    });

    if (loginResult.isErr()) {
      toast.error(loginResult.error, { id: TOAST_ID });
      setActiveOperation(null);
      return;
    }

    if ("loginWithEmailAndPassword" in loginResult.value) {
      navigate({ pathname: Routes.LandingSheetLoginLanding, search });
      return;
    }

    const {
      needsTwoFactor,
      profileStatus,
      userStatus: _userStatus,
    } = loginResult.value;
    const userStatus = _userStatus as UserStatus;

    const updatedUser = { status: userStatus };
    updateUser(updatedUser);

    const nextStep = nextAuthStep({
      profileStatus,
      needsTwoFactor,
      userStatus,
      transferKind,
      search,
    });

    if (nextStep.isErr()) {
      toast.error(nextStep.error, { id: TOAST_ID });
      return;
    }

    if (isOnboardingAppRoute(nextStep.value.to.pathname)) {
      initializeOnboarding(profileStatus, updatedUser, {
        isAsynchronous: true,
      });
    } else {
      navigate(nextStep.value.to, nextStep.value.options);
    }
  };

  const handleRequestWalletSignature = async () => {
    Posthog.capture(TelemetryEvents.walletLoginSignMessageRequest);
    setActiveOperation("fetching-login-message");

    const loginMessageResult = await resolveLoginMessage({
      input: {
        address: walletAddress,
        network,
      },
    });

    if (loginMessageResult.isErr()) {
      toast.error(ErrorMessages.landingSheet.GENERATE_SIGNING_MESSAGE_ERROR, {
        id: TOAST_ID,
      });
      setActiveOperation(null);
      return;
    }

    bus!.on(
      MessageKind.RETURN_SIGNED_MESSAGE_RESULT,
      async (message: Message) => {
        if (message.kind == MessageKind.RETURN_SIGNED_MESSAGE_RESULT) {
          Posthog.capture(
            TelemetryEvents.walletLoginSignMessageReceiveResponse,
          );

          setActiveOperation("logging-in");
          setTimeout(async () => {
            if (message.payload.signedMessage) {
              await onReturnSignedMessage(message.payload.signedMessage);
            } else {
              Posthog.capture(TelemetryEvents.walletLoginSignMessageFail);
              setActiveOperation(null);
              toast.error(
                ErrorMessages.landingSheet.WALLET_SIGNING_GENERIC_ERROR,
                { id: TOAST_ID },
              );
            }
          }, 200);
        }
      },
    );

    setActiveOperation("awaiting-signature");

    bus!.emit({
      kind: MessageKind.REQUEST_SIGNED_MESSAGE,
      payload: { messageToSign: loginMessageResult.value.messageToSign },
    });
  };

  const shouldShowInterstitialMessage =
    activeOperation === "awaiting-signature" ||
    activeOperation === "logging-in";

  return (
    <motion.div
      key="LandingSheetVerification"
      initial={{ opacity: 0 }}
      animate={{
        opacity: 1,
        transition: { ...spring },
      }}
      exit={{ opacity: 0, y: 50 }}
    >
      <div className="flex flex-col gap-2">
        <div className="w-full">
          <Button
            onClick={handleRequestWalletSignature}
            disabled={shouldShowInterstitialMessage}
            isLoading={shouldShowInterstitialMessage}
            className="w-full"
            themeOverride="transfer-button"
          >
            {shouldShowInterstitialMessage ? "Sign Message" : "Verify Address"}
          </Button>
        </div>
      </div>
    </motion.div>
  );
};
