// Export everything except the types module.
// This way, consumers in the workspace can pull TS modules via `import foo from "@tigris/common"
// and their src/types.ts files can do `export * from "@tigris/common/src/types"`
export * from "./utils/formHelpers";
export * from "./utils/getAge";
export * from "./utils/validation";
export * from "./utils/assets";
export * from "./utils/walletAddress";
export * from "./utils/appearance";
export * from "./telemetry";
export * from "./utils/api";
export * from "./utils/string";

/** The default user ID provided via the API for users prior to login. */
export const DEFAULT_ANON_USER_ID = "u_00000000000000000000000000";
