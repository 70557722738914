import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { AppContext } from "@src/contexts/AppContextProvider";
import {
  AuthCode,
  AuthCodeRef,
  ThemeName,
  ErrorMessages,
} from "@tigris/mesokit";
import { Routes } from "@src/utils/constants";
import { TransferKind, TwoFactorMethod } from "@src/generated/sdk";
import { motion } from "framer-motion";
import { toast } from "sonner";
import { useLocation, useNavigate } from "react-router-dom";
import { motionVariants } from "@src/utils/animation";
import { networkFromCAIPNetwork } from "@tigris/common";
import { useOnboarding } from "@src/hooks/useOnboarding";
import { CountryCodeAlpha2, User, UserStatus } from "@src/types";
import { nextAuthStep } from "@src/utils/authFlow";
import { isOnboardingAppRoute } from "@src/utils/routeHelpers";

const TOAST_ID = "TransferSheet2Fa";

export const TransferSheet2Fa = () => {
  const authCodeRef = useRef<AuthCodeRef>(null);
  const {
    api: {
      resolveVerify2FA,
      resolveSend2FACode,
      resolveUser,
      resolveAddWallet,
    },
    updateUser,
    configuration: { walletAddress, transferKind, sourceAsset, network },
  } = useContext(AppContext);
  const navigate = useNavigate();
  const { search, state } = useLocation();
  const [loginUserIsInFlight, setLoginUserIsInFlight] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { initializeOnboarding, supportedPaymentMethods } = useOnboarding();

  const loginUser = useCallback(async () => {
    setLoginUserIsInFlight(true);

    const resolveUserResult = await resolveUser();

    if (resolveUserResult.isErr()) {
      setLoginUserIsInFlight(false);
      toast.error(resolveUserResult.error, {
        id: TOAST_ID,
      });

      return;
    }

    const user = resolveUserResult.value;
    const {
      walletInstruments,
      fiatInstruments,
      depositAddressInstruments,
      status,
    } = user;

    var updatedUser: Partial<User> = {};
    if (
      fiatInstruments?.__typename === "FiatInstruments" &&
      walletInstruments?.__typename === "WalletInstruments" &&
      depositAddressInstruments?.__typename === "DepositAddressInstruments" &&
      status
    ) {
      updatedUser = {
        ...user,
        residentialAddress: user.residentialAddress ?? undefined,
        residenceCountry: user.residentialAddress
          ? (user.residentialAddress.countryCode as CountryCodeAlpha2)
          : undefined,
        theme: user.theme as ThemeName,
        fiatInstruments,
        walletInstruments,
        depositAddressInstruments,
      };
      updateUser(updatedUser, supportedPaymentMethods);
    }

    if (walletInstruments?.__typename === "WalletInstruments") {
      const walletExistsForUser = walletInstruments.collection.some(
        ({ address }) => address.toLowerCase() === walletAddress.toLowerCase(),
      );

      if (!walletExistsForUser) {
        // Add it to the user via the API
        const addWalletResult = await resolveAddWallet({
          input: {
            walletAddress,
          },
        });

        if (addWalletResult.isErr()) {
          setLoginUserIsInFlight(false);
          toast.error(addWalletResult.error, { id: TOAST_ID });
          return;
        }

        const user = addWalletResult.value.user;

        if (
          user?.__typename === "User" &&
          user.walletInstruments?.__typename === "WalletInstruments"
        ) {
          updateUser({ walletInstruments: user.walletInstruments });
        } else {
          setLoginUserIsInFlight(false);
          toast.error(ErrorMessages.common.LOOKUP_USER_API_ERROR, {
            id: TOAST_ID,
          });
          return;
        }
      }

      if ((status as UserStatus) === UserStatus.ACTIVE) {
        if (transferKind === TransferKind.CASH_IN) {
          navigate({ pathname: Routes.TransferSheet, search });
        } else {
          const depositAddressExists = !!(
            depositAddressInstruments?.__typename ===
              "DepositAddressInstruments" &&
            depositAddressInstruments.collection.find((depositAddress) => {
              const asset = depositAddress.asset;
              return (
                asset.symbol === sourceAsset &&
                networkFromCAIPNetwork(asset.network) === network
              );
            })
          );
          navigate({
            pathname: depositAddressExists
              ? Routes.TransferSheet
              : Routes.TransferSheetDepositAddress,
            search,
          });
        }
      } else {
        const toResult = nextAuthStep({
          profileStatus: state.profileStatus,
          needsTwoFactor: false,
          transferKind,
          userStatus: status as UserStatus,
          search,
        });

        if (toResult.isErr()) {
          toast.error(toResult.error, { id: TOAST_ID });
          return;
        }

        if (isOnboardingAppRoute(toResult.value.to.pathname)) {
          initializeOnboarding(state.profileStatus, updatedUser, {
            isAsynchronous: true,
          });
        } else {
          navigate(toResult.value.to, { ...toResult.value.options });
        }
      }
    }
  }, [
    initializeOnboarding,
    navigate,
    network,
    resolveAddWallet,
    resolveUser,
    search,
    sourceAsset,
    state?.profileStatus,
    supportedPaymentMethods,
    transferKind,
    updateUser,
    walletAddress,
  ]);

  const verify2FA = useCallback(
    async (code: string) => {
      setIsLoading(true);

      const resolveVerify2FAResult = await resolveVerify2FA({
        input: { code },
      });

      if (resolveVerify2FAResult.isErr()) {
        setIsLoading(false);
        authCodeRef.current?.clear();
        toast.error(resolveVerify2FAResult.error);

        setTimeout(() => authCodeRef.current?.focus());
      } else {
        loginUser();
      }
    },
    [loginUser, resolveVerify2FA],
  );

  const onAuthCodeChange = useMemo(
    () => async (result: string) => {
      if (result.length === 6) {
        await verify2FA(result);
      }
    },
    [verify2FA],
  );

  const sendCode = useCallback(async () => {
    const send2FACodeResult = await resolveSend2FACode({
      input: { method: TwoFactorMethod.PHONE },
    });

    if (send2FACodeResult.isErr()) {
      toast.error(send2FACodeResult.error, { id: TOAST_ID });
      setIsLoading(false);
      authCodeRef.current?.clear();
    } else {
      setIsLoading(false);

      setTimeout(() => {
        authCodeRef.current?.focus();
      });
    }
  }, [resolveSend2FACode]);

  useEffect(() => {
    setTimeout(() => {
      authCodeRef.current?.focus();
    }, 340);
  }, []);

  return (
    <motion.div
      key="TransferSheet:2Fa"
      data-testid="TransferSheet:2Fa"
      initial="initial"
      animate="animate"
      exit="exit"
      variants={motionVariants.slideInFromRight}
    >
      <form
        id="TwoFactorAuth"
        name="TwoFactorAuth"
        className="text-center"
        data-testid="TwoFactorAuth"
      >
        <AuthCode
          disabled={isLoading || loginUserIsInFlight}
          onAuthCodeChange={onAuthCodeChange}
          ref={authCodeRef}
          onResend={sendCode}
        />
      </form>
    </motion.div>
  );
};
