import { MesoKitContext } from "@tigris/mesokit";
import { AppContextProvider } from "./contexts/AppContextProvider";
import { SardineRiskSession } from "./components/SardineRiskSession";
import { StandaloneLayout } from "./components/Standalone/StandaloneLayout";
import { HeadlessNavigator } from "./HeadlessNavigator";
import { MainLayout } from "./components/MainLayout";
import { toast } from "sonner";
import { Routes } from "./utils/constants";
import { LandingSheetLoginLanding } from "./components/LandingSheetLoginLanding";
import { LandingAndTransferContainer } from "./components/LandingAndTransferContainer";
import { ResolveLandingView } from "./middleware/ResolveLandingView";
import { LandingSheet } from "./components/LandingSheet";
import { LandingSheetLoginEntry } from "./components/LandingSheetLoginEntry";
import { TransferSheet } from "./components/TransferSheet";
import { TransferSheet2Fa } from "./components/TransferSheet2Fa";
import { TransferSheetStatus } from "./components/TransferSheetStatus";
import { RouteObject } from "react-router-dom";
import { StandaloneLoader } from "./components/Standalone/StandaloneLoader";
import { TransferSheetDepositAddress } from "./components/TransferSheetDepositAddress";
import { InlineLayout } from "./components/Inline/InlineLayout";
import { IntegrationMode } from "./types";
import { CheckoutContextProvider } from "./contexts/CheckoutContextProvider";
import { OnboardingRenderContainer } from "./components/OnboardingRenderContainer";
import { OnboardingContextProvider } from "./contexts/OnboardingContextProvider";
import { TransferUnavailable } from "./components/TransferUnavailable";
import { ActivateUser } from "./components/ActivateUser";
import { RewardsLanding } from "./components/RewardsLanding";
import { FinishSignup } from "./components/FinishSignup";
import { ApplePayContextProvider } from "./contexts/ApplePayContextProvider";
import { Posthog, Sentry } from "@tigris/common";

const commonRoutes = [
  {
    path: Routes.LandingSheetLoginEntry,
    element: <LandingSheetLoginEntry />,
  },
  {
    path: Routes.TransferSheet,
    element: <TransferSheet />,
  },
  {
    path: Routes.TransferSheet2Fa,
    element: <TransferSheet2Fa />,
  },
  {
    path: Routes.TransferSheetDepositAddress,
    element: <TransferSheetDepositAddress />,
  },
  {
    path: Routes.TransferSheetStatus,
    element: <TransferSheetStatus />,
  },
  { path: Routes.FinishSignup, element: <FinishSignup /> },
];

// This needs to be hardened: https://linear.app/meso-network/issue/MESO-1667/harden-integration-mode-decisioning-in-transfer-app
let embeddedMode = IntegrationMode.EMBEDDED;
try {
  if (new URLSearchParams(location.search).get("mode") === "webview") {
    embeddedMode = IntegrationMode.WEBVIEW;
  }
} catch (error) {
  Sentry.captureException(error);
}

const embeddedRouter = [
  {
    element: (
      // Provide telemetry library references to Mesokit components
      <MesoKitContext.Provider
        value={{ sentry: Sentry, toast, posthog: Posthog }}
      >
        <AppContextProvider
          configurationParams={new URLSearchParams(location.search)}
          mode={embeddedMode}
        >
          <ApplePayContextProvider>
            <OnboardingContextProvider>
              <SardineRiskSession>
                <CheckoutContextProvider>
                  <HeadlessNavigator>
                    <MainLayout />
                  </HeadlessNavigator>
                </CheckoutContextProvider>
              </SardineRiskSession>
            </OnboardingContextProvider>
          </ApplePayContextProvider>
        </AppContextProvider>
      </MesoKitContext.Provider>
    ),
    children: [
      {
        path: "/",
        element: <LandingAndTransferContainer />,
        children: [
          {
            index: true,
            element: (
              <ResolveLandingView>
                <LandingSheet />
              </ResolveLandingView>
            ),
          },
          {
            path: Routes.LandingSheetLoginLanding,
            element: <LandingSheetLoginLanding />,
          },
          ...commonRoutes,
        ],
      },
      {
        path: Routes.Onboarding,
        element: <OnboardingRenderContainer />,
      },

      {
        path: Routes.TransferUnavailable,
        element: <TransferUnavailable />,
      },
      {
        path: Routes.ActivateUser,
        element: <ActivateUser />,
      },
    ],
  },
];

const standaloneRouter = [
  {
    element: (
      // Provide telemetry library references to Mesokit components
      <MesoKitContext.Provider
        value={{ sentry: Sentry, toast, posthog: Posthog }}
      >
        <StandaloneLoader>
          <SardineRiskSession>
            <CheckoutContextProvider>
              <StandaloneLayout />
            </CheckoutContextProvider>
          </SardineRiskSession>
        </StandaloneLoader>
      </MesoKitContext.Provider>
    ),
    children: [
      {
        path: "/",
        children: [
          {
            index: true,
            path: Routes.LandingSheet,
            element: <LandingSheetLoginLanding />,
          },
          ...commonRoutes,
          {
            path: Routes.Onboarding,
            element: <OnboardingRenderContainer />,
          },
        ],
      },
      {
        path: Routes.TransferUnavailable,
        element: <TransferUnavailable />,
      },
      {
        path: Routes.ActivateUser,
        element: <ActivateUser />,
      },
    ],
  },
];

const inlineRouter = [
  {
    path: "/",
    element: (
      // Provide telemetry library references to Mesokit components
      <MesoKitContext.Provider
        value={{ sentry: Sentry, toast, posthog: Posthog }}
      >
        <AppContextProvider
          configurationParams={new URLSearchParams(location.search)}
          mode={IntegrationMode.INLINE}
        >
          <ApplePayContextProvider>
            <OnboardingContextProvider>
              <HeadlessNavigator>
                <SardineRiskSession>
                  <CheckoutContextProvider>
                    <InlineLayout />
                  </CheckoutContextProvider>
                </SardineRiskSession>
              </HeadlessNavigator>
            </OnboardingContextProvider>
          </ApplePayContextProvider>
        </AppContextProvider>
      </MesoKitContext.Provider>
    ),
    children: [
      {
        path: Routes.LandingSheet,
        element: (
          <ResolveLandingView>
            <LandingSheet />
          </ResolveLandingView>
        ),
      },
      {
        path: Routes.LandingSheetLoginLanding,
        element: <LandingSheetLoginLanding />,
      },
      {
        path: Routes.LandingSheetLoginEntry,
        element: <LandingSheetLoginEntry />,
      },
      {
        path: Routes.TransferSheet,
        element: <TransferSheet />,
      },

      {
        path: Routes.TransferSheet2Fa,
        element: <TransferSheet2Fa />,
      },
      {
        path: Routes.TransferSheetStatus,
        element: <TransferSheetStatus />,
      },
      {
        path: Routes.TransferUnavailable,
        element: <TransferUnavailable />,
      },
      {
        path: Routes.ActivateUser,
        element: <ActivateUser />,
      },
    ],
  },
];

// The base path for this router is `/rewards`
const rewardsRouter = [
  {
    path: "/",
    // Provide telemetry library references to Mesokit components
    element: (
      <MesoKitContext.Provider
        value={{ sentry: Sentry, toast, posthog: Posthog }}
      >
        <RewardsLanding />
      </MesoKitContext.Provider>
    ),
  },
];

export const routers: Record<string, RouteObject[]> = {
  "/app": embeddedRouter,
  "/embedded": embeddedRouter,
  "/": standaloneRouter,
  "/standalone": standaloneRouter,
  "/inline": inlineRouter,
  "/rewards": rewardsRouter,
};
